<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-form :model="searchForm" ref="searchForm" label-width="48px" inline size="small" class="search-form">
          <el-form-item label="意见描述" prop="title" label-width="80px">
            <el-input v-model="searchForm.title" clearable/>
          </el-form-item>

          <el-form-item label="昵称" prop="nick_name">
            <el-input v-model="searchForm.nick_name" clearable/>
          </el-form-item>

          <el-form-item label="预留联系方式" prop="contract" label-width="100px">
            <el-input v-model="searchForm.contract" clearable/>
          </el-form-item>

          <el-form-item label="手机号码" prop="phone" label-width="80px">
            <el-input v-model="searchForm.phone" clearable/>
          </el-form-item>

          <el-form-item label="状态" prop="status">
            <el-select v-model="searchForm.status" clearable placeholder="请选择状态">
              <el-option
                  v-for="status in statusList"
                  :key="status.id"
                  :label="status.text"
                  :value="status.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item style="width: 200px; text-align: left">
            <el-button type="primary" size="small" icon="el-icon-search" @click="onSearchClick">搜索</el-button>
            <el-button size="small" icon="el-icon-refresh-right" style="margin-left: 10px" @click="onResetSearch">重置
            </el-button>
          </el-form-item>
        </el-form>

        <ApeTable ref="apeTable" :data="feedbackList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column v-if="buttonType === 'icon'" label="操作"
                           width="150"
                           fixed="right">
            <template slot-scope="scope">
              <el-tooltip content="回复" placement="left"
                          v-if="userPermissions.indexOf('feedback_edit') !== -1">
                <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"/>
              </el-tooltip>

              <template v-if="userPermissions.indexOf('feedback_delete') !== -1">
                <el-tooltip
                    effect="dark"
                    content="作废"
                    placement="right"
                    v-if="scope.row.status !== 'removed'"
                >
                  <el-popover placement="top" width="150" v-model="scope.row.visible">
                    <p>确定作废该反馈记录吗？</p>
                    <div style="text-align: right; margin: 0;">
                      <el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>
                      <el-button type="danger" size="mini" @click="discardButton(scope.row.id)">确定</el-button>
                    </div>
                    <el-button slot="reference" type="danger" size="mini" icon="el-icon-document-delete"/>
                  </el-popover>
                </el-tooltip>

                <el-tooltip content="已作废" placement="right" v-else>
                  <el-button size="mini" icon="el-icon-document-delete" type="info"/>
                </el-tooltip>
              </template>

            </template>
          </el-table-column>

          <el-table-column v-if="buttonType==='text'" label="操作"
                           width="150"
                           fixed="right">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  v-if="userPermissions.indexOf('feedback_edit') !== -1"
                  @click="editButton(scope.row.id)"
              >回复
              </el-button>

              <template v-if="userPermissions.indexOf('feedback_delete') !== -1">
                <el-popover
                    v-if="scope.row.status !== 'removed'"
                    placement="top"
                    width="150"
                    v-model="scope.row.visible"
                >
                  <p>确定作废该反馈记录吗？</p>
                  <div style="text-align: right; margin: 0;">
                    <el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>
                    <el-button type="danger" size="mini" @click="discardButton(scope.row.id)">确定</el-button>
                  </div>
                  <el-button slot="reference" type="danger" size="mini">作废</el-button>
                </el-popover>

                <el-button size="mini" v-else disabled>已作废</el-button>
              </template>

            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>

    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="formData" :rules="rules" ref="feedbackForm" label-position="right"
                 label-width="96px"
                 :disabled="isShowFeedback"
        >
          <el-row>
            <el-col :span="22">
              <el-form-item label="意见描述">
                <el-input type="textarea"
                          :autosize="{minRows:2, maxRows:5}"
                          v-model="formData.title"
                          :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="手机号" prop="name">
                <el-input v-model="formData.phone"
                          :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="预留联系方式" prop="name">
                <el-input v-model="formData.contract"
                          :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-col :span="22">
            <el-form-item label="截图">
              <ApeUploader :allowUpdate="false"
                           :upload-file-list="uploadFileList" uploadTip=" "></ApeUploader>
<!--              <div v-for="pic in formData.pics" class="el-col-sm-15 el-col-md-10 el-col-lg-10">-->
<!--                <img :src="pic" height="80">-->
<!--              </div>-->
            </el-form-item>
          </el-col>


            <el-col :span="22">
              <el-form-item label="回复内容" prop="reply_content">
                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4}"
                          v-model="formData.reply_content"
                          maxlength="500"
                          show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>

        </el-form>
      </template>
    </ApeDrawer>

  </div>
</template>

<script>

const DEL = "del";

import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import ApeUploader from '@/components/ApeUploader'
import Sortable from 'sortablejs'
import {mapGetters} from 'vuex'


export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    ApeUploader
  },
  data() {
    return {
      loadingStatus: false,
      columns: [
        {
          title: '意见描述',
          value: 'title'
        },
        {
          title: "截图",
          type: "image",
          value: "pics"
        },
        {
          title: '状态',
          value: 'status',
          value_display: 'status_alias',
          class_plan: [
            {
              value: "ready",
              class: ["el-tag", "el-tag--warning"]
            },
            {
              value: "replied",
              class: "el-tag el-tag--success"
            },
            {
              value: "removed",
              class: "el-tag el-tag--danger"
            }
          ]
        },
        {
          title: "用户昵称",
          value: "nick_name",
        },
        {
          title: "手机号",
          value: "phone",
        },
        {
          title: "预留联系方式",
          value: "contract",
        },
        {
          title: '创建时间',
          value: 'created_at'
        },
      ],
      // 表格列表数据
      feedbackList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0, // 分页的offset,序号列使用
      },

      // 表单结构
      formData: {},

      // 表单验证
      rules: {
        reply_content: [
          {required: true, message: '请输入回复内容', trigger: 'blur'}
        ]
      },

      // 抽屉数据
      drawerData: {
        visible: false,
        loading: false,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },

      typeList: [],
      statusList: [],
      selectedStatus: '',
      //搜索表单
      searchForm: {
        title: '',
        nick_name: '',
        contract: '',
        phone: '',
        status: '',
      },

      //反馈i哦ID
      isShowFeedback: false,

      uploadFileList: []
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  mounted() {
    this.initFeedbackList();
    this.getCorrectionStatus()
  },
  methods: {
    // 获取反馈类型
    async getCorrectionStatus() {
      try {
        this.statusList = await this.$api.commonDict('user_feedback_status');
      } catch (e) {
        this.$message('无法获取状态')
      }
    },

    // 切换页码操作
    switchPaging() {
      this.initFeedbackList()
    },

    // 响应编辑按钮
    async editButton(id) {

      this.uploadFileList = []
      if (!id) return
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '回复反馈(ID：' + id + ')';
      try {
        let params = {}
        params.id = id
        let data = await this.$api.getFeedbackInfo(params)
        console.log(data)
        data && (this.formData = data)
        if (Array.isArray(this.formData.pics) && this.formData.pics.length > 0) {
          for (let index = 0; index < this.formData.pics.length; index++) {
            this.uploadFileList.push({
              id: this.formData.id,
              url: this.formData.pics[index],
              title: this.formData.id
            })
          }
        }

        this.drawerData.loading = false
      } catch (e) {
        this.drawerData.loading = false
      }
    },

    // 响应作废按钮
    async discardButton(id) {
      if (!id) return
      let params = {}
      params.id = id
      params.status = 'removed'
      try {
        await this.$api.updateFeedback(params);
        await this.initFeedbackList()
        this.$message.success('操作成功')
      } catch (e) {
        await this.initFeedbackList()
        this.$message.success('操作失败')
      }
    },

    // 处理抽屉关闭
    drawerClose() {
      this.resetFormData();
      this.drawerData.visible = false;
      this.drawerData.loading = true
      this.uploadFileList = []
    },

    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      let {id, reply_content} = this.formData
      if (!id || !reply_content) {
        return
      }
      try {
        let params = {}
        params.feedback_id = id
        params.reply_content = reply_content
        await this.$api.replyFeedback(params)
        await this.initFeedbackList()
        this.$message.success('回复成功')
        this.drawerClose()
      } catch (e) {
        this.$message.error('回复失败')
        await this.initFeedbackList()
        this.drawerClose()
      }
    },

    // 初始化数据
    resetFormData() {
      this.formData = {};
      this.$nextTick(() => {
        this.$refs['feedbackForm'].resetFields();
      })
    },

    // 初始化题库列表
    async initFeedbackList(type) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);
      for (let param in this.searchForm) {
        if (!this.searchForm[param]) {
          continue;
        }
        inputData[param] = this.searchForm[param];
      }
      try {
        let res = await this.$api.getFeedbackList(inputData)
        let {data, pages} = res
        data && (this.feedbackList = data);
        pages && (this.pagingData.total = pages.total);
        pages && (this.pagingData.offset = pages.offset);
        this.loadingStatus = false;
      } catch (e) {
        this.loadingStatus = false;
      }
    },

    //搜索
    onSearchClick(formData) {
      this.$refs['apeTable'].resetCurPageSize();
      this.initFeedbackList(DEL, formData);
    },

    //搜索重置
    onResetSearch() {
      this.searchForm = {};
      this.initFeedbackList();
    },
  },

}
</script>

<style lang="stylus" scoped>
.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0
  margin-right 12px

.el-radio.is-bordered
  width 100px

.el-color-picker
  position absolute

.search-form
  display flex
  flex-wrap wrap

  .el-form-item
    flex 1
    min-width 320px
    max-width 350px

</style>
