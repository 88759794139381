<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <el-row class="table-header" type="flex" justify="space-between">
          <el-col :span="18">
            <el-button type="primary" size="medium"
                       v-if="userPermissions.indexOf('hr_create') != -1 "
                       @click="addButton()">添加
            </el-button>
            <el-button type="primary" size="medium"
                       v-if="userPermissions.indexOf('hr_import') != -1 "
                       @click="importButton()">导入
            </el-button>
            <a :href="temUrl" target="_blank" style="color: #1890ff;margin-left: 10px">导入模板下载</a>
          </el-col>
          <!--                    <el-col :span="5" :offset="1">-->
          <!--                        <change-question-bank @confirm="onChangeQuestionBankClick"></change-question-bank>-->
          <!--                    </el-col>-->
        </el-row>

        <el-row class="table-search">
          <el-form :inline="true" :model="searchForm" class="demo-form-inline">
            <el-form-item label="历年试卷名称">
              <el-input placeholder="请输入历年试卷名称" clearable v-model="searchForm.name"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick" style="margin-left: 12px">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="onResetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="questionBankList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
              slot="first-column"
              width="80"
              align="center"
              label="Drag">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                <span class="drag-handle" :data-id="scope.row.id"><i class="el-icon-rank"></i></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='icon'"
              label="操作"
              width="150"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>
                     <el-tooltip effect="dark" content="选题" placement="top-start"
                                 v-if="userPermissions.indexOf('hr_add') != -1">
                  <el-button size="mini" type="primary" icon="el-icon-plus"
                             @click="addQuestionButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('hr_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>
                  <el-tooltip effect="dark" content="删除" placement="top-start"
                              v-if="userPermissions.indexOf('hr_delete') != -1">
                  <el-button size="mini"
                             type="danger"
                             icon="el-icon-delete"
                             @click="deleteButton(scope.row.id)"></el-button>
                </el-tooltip>
                <!--<el-tooltip effect="dark" content="删除" placement="top-start">-->
                <!--<span>-->
                <!--<el-popover-->
                <!--v-if="userPermissions.indexOf('test_history_real_paper_delete') != -1"-->
                <!--placement="top"-->
                <!--width="150"-->
                <!--v-model="scope.row.visible">-->
                <!--<p>确定要删除记录吗？</p>-->
                <!--<div style="text-align: right; margin: 0;">-->
                <!--<el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
                <!--<el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
                <!--</div>-->
                <!--<el-button slot="reference" type="danger" size="mini" icon="el-icon-delete"></el-button>-->
                <!--</el-popover>-->
                <!--</span>-->
                <!--</el-tooltip>-->
              </span>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='text'"
              label="操作"
              width="200"
              fixed="right"
          >
            <template slot-scope="scope">
              <div>
                <el-button size="mini" type="primary"
                           v-if="userPermissions.indexOf('hr_add') != -1"
                           @click="addQuestionButton(scope.row.id)">选题
                </el-button>

                <el-button size="mini"
                           v-if="userPermissions.indexOf('hr_edit') != -1"
                           @click="editButton(scope.row.id)">编辑
                </el-button>

                <el-button size="mini"
                           type="danger"
                           v-if="userPermissions.indexOf('hr_delete') != -1"
                           @click="deleteButton(scope.row.id)">删除
                </el-button>
                <!--<el-popover-->
                <!--v-if="userPermissions.indexOf('test_history_real_paper_delete') != -1"-->
                <!--placement="top"-->
                <!--width="150"-->
                <!--v-model="scope.row.visible">-->
                <!--<p>确定要删除记录吗？</p>-->
                <!--<div style="text-align: right; margin: 0;">-->
                <!--<el-button type="text" size="mini" @click="scope.row.visible=false">取消-->
                <!--</el-button>-->
                <!--<el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定-->
                <!--</el-button>-->
                <!--</div>-->
                <!--<el-button slot="reference" type="danger" size="mini">删除</el-button>-->
                <!--</el-popover>-->
              </div>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-col :span="18" class="content-left">
          <el-form ref="realForm" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="当前题库">
              <el-input v-model="curChooseBankName" disabled></el-input>
            </el-form-item>
            <el-form-item label="名称" prop="name">
              <el-input v-model="formData.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="描述">
              <el-input v-model="formData.description" placeholder="请输入描述"></el-input>
            </el-form-item>
            <el-form-item label="及格分" prop="pass_score">
              <el-col>
                <el-col :span="19">
                  <el-input v-model="formData.pass_score" onkeyup="value=value.replace(/[^\d]/g,'')"
                            placeholder="请输入及格分"></el-input>
                </el-col>
                <el-col :span="4" :offset="1">
                  <label>分</label>
                </el-col>
              </el-col>

            </el-form-item>
            <el-form-item label="考试时长" prop="pass_score">
              <el-col>
                <el-col :span="19">
                  <el-input v-model="formData.exam_time" onkeyup="value=value.replace(/[^\d]/g,'')"
                            placeholder="请输入考试时长"></el-input>
                </el-col>
                <el-col :span="4" :offset="1">
                  <label>分钟</label>
                </el-col>
              </el-col>
            </el-form-item>
            <el-form-item label="难度" prop="difficulty">
              <el-select
                  v-model="formData.difficulty"
                  filterable
                  clearable
                  placeholder="请选择难度"
              >
                <el-option
                    v-for="item in difficultList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="formData.status">
                <el-radio label="0" border>启用</el-radio>
                <el-radio label="-1" border>禁用</el-radio>
              </el-radio-group>
            </el-form-item>

          </el-form>
        </el-col>
      </template>
    </ApeDrawer>


    <ApeDrawer :drawerData="drawerImportData" @drawerClose="importDrawerClose" @drawerConfirm="importDrawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="importDrawerForm" label-width="96px">
          <el-form-item label="当前题库">
            <el-input v-model="curChooseBankName" :disabled="true" maxlength="10"/>
          </el-form-item>

          <el-form-item label="上传文件">
            <uploader-file :limit="1" @handleUploadSuccess="handleUploadSuccess"
                           @handleUploadRemove="handleUploadRemove"
                           :upload-file-list="uploadFileList"
            ></uploader-file>
          </el-form-item>
        </el-form>
      </template>
    </ApeDrawer>

    <el-dialog :visible.sync="showPreviewDialog" :close-on-click-modal="false" width="80%" title="导入历史真题前预览" :destroy-on-close="true"
               :append-to-body="true" v-loading.fullscreen.lock="fullscreenLoading">
      <question-import-preview :configList="configList" @finish="onPreviewFinish"></question-import-preview>
    </el-dialog>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
const WINDOW_LOCAL_ORIGIN = process.env.VUE_APP_POROXY_TARGET;
import PageHeaderLayout from '@/layouts/PageHeaderLayout';
import ApeTable from '@/components/ApeTable';
import UploaderFile from '@/components/UploaderFile'
import ApeDrawer from '@/components/ApeDrawer'
import {mapGetters} from 'vuex';
import Sortable from 'sortablejs';
import QuestionImportPreview from "@/pages/question_bank/components/QuestionImportPreview";

export default {
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    UploaderFile,
    QuestionImportPreview
  },
  data() {
    return {
      configList: [],
      fullscreenLoading: false,
      showPreviewDialog: false,
      loadingStatus: false,
      columns: [
        {
          title: '名称',
          value: 'name',
          width: '240'
        },
        {
          title: '描述',
          value: 'description',
        },
        {
          title: '难度',
          value: "difficulty_alias",
        },
        {
          title: '总分',
          value: "score",
        },
        {
          title: '及格分',
          value: "pass_score",
        },
        {
          title: '题目数量',
          value: "question_count",
        },
        {
          title: "考试时长",
          value: 'exam_time',
        },
        {


          title: '状态',
          value: 'status',
          value_display: "status_alias",
          style_plan: [
            {
              value: "0",
              style: "color:#67C23A;"
            },
            {
              value: "1",
              style: "color:#F56C6C;"
            }
          ]
        }
      ],
      // 表格列表数据
      questionBankList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        offset: 0, // 分页的offset,序号列使用
      },
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },
      //抽屉表单
      formData: {
        name: "",
        difficulty: 1,
        description: "",
        pass_score: "",
        exam_time: "",
        status: '0',
      },
      //上传文件抽屉数
      drawerImportData: {
        visible: false,
        loading: false,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '560px',
      },

      uploadFileList: [],

      // 表单验证
      rules: {
        name: [
          {required: true, message: '输入标题', trigger: 'blur'},
        ],
        difficulty: [
          {required: true, message: '请输入难度', trigger: 'blur'},
        ],
        status: [
          {required: true, message: '请选择题型状态', trigger: 'blur'}
        ],
        description: [
          {required: true, message: '请输入试卷描述', trigger: 'blur'}
        ],
        pass_score: [
          {required: true, message: '请输入及格分', trigger: 'blur'}
        ],
        exam_time: [
          {required: true, message: '请输入考试时长', trigger: 'blur'}
        ],
      },

      //上传文件
      importRule: {
        name: [
          {required: true, message: '输入试卷名称', trigger: 'blur'},
        ],
      },

      //难度
      difficultList: [
        {
          title: "容易",
          value: 1
        },
        {
          title: "困难",
          value: 3
        }
      ],

      searchForm: {},

      searchCondition: {},

      //导入题库表单数据
      importDrawerForm: {},

      temUrl: "",
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType', 'questionBankInfo', 'curChooseBankId', 'curChooseBankName'])
  },
  methods: {
    // 切换页码操作
    async switchPaging() {
      this.initRealList(NO_DEL)
    },

    // 响应添加按钮
    async addButton() {
      this.drawerData.visible = true;
      this.drawerData.title = '添加历史试卷';
      this.$nextTick(() => {
        this.drawerData.loading = false;
        this.formData.qb_id = this.curChooseBankId;
      });
    },


    //响应导入按钮
    async importButton(id) {
      this.drawerImportData.visible = true;
      this.drawerImportData.title = '导入历史真题';
      this.$nextTick(() => {
        this.drawerImportData.loading = false
      })
    },

    // 响应编辑按钮
    async editButton(id) {
      this.drawerData.loading_text = '玩命加载中……';
      this.drawerData.visible = true;
      this.drawerData.title = '修改历史试卷';
      let {info} = await this.$api.getRealInfo(id);
      this.$nextTick(() => {
        this.drawerData.loading = false;
        this.formData = info;
        this.formData.qb_id = this.curChooseBankId;
        this.formData.exam_time = parseInt(this.formData.exam_time) / 60 + "";
        this.formData.difficulty = Math.ceil(info.difficulty);
      });
    },

    //响应题型添加
    addQuestionButton(id) {
      let qbId = this.curChooseBankId;
      this.$router.push(this.$route.path + '/' + id + '/' + qbId + '/create');
    },

    // 处理抽屉关闭
    drawerClose() {
      this.resetFormData();
      this.drawerData.visible = false;
      this.drawerData.loading = true
    },

    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['realForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },

    // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……';
      this.drawerData.loading = true;
      // 调用接口
      this.formData.exam_time = parseInt(this.formData.exam_time) * 60 + "";

      let id = await this.$api.storeRealList(this.formData);
      this.$nextTick(() => {
        this.drawerData.visible = false
      });
      this.$nextTick(() => {
        if (id) {
          this.initRealList(NO_DEL)
        }
      });
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      });
      this.resetFormData()
    },

    // 相应删除按钮
    deleteButton(id) {
      this.$confirm('是否删除该试卷?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deleteReal(id);
        if (info == 'ok') {
          this.$nextTick(() => {
            this.initRealList(DEL);
            this.$message.success("删除成功");
          })
        } else {
          this.$message.success("删除失败");
        }
      })
    },

    // 初始化数据
    resetFormData() {
      // 初始化form表单
      this.formData = {
        name: "",
        difficulty: 1,
        description: "",
        pass_score: "",
        exam_time: "",
        status: '0',
      };
      this.$nextTick(() => {
        this.$refs['realForm'].resetFields();
      })
    },

    async initRealList(type, params = {}) {
      this.loadingStatus = true;
      let inputData = this.$refs['apeTable'].getPagingInfo(type);

      for (let param in params) {
        inputData[param] = params[param];
      }

      // let qbId = this.curChooseBankId;

      // if (!qbId) {
      //     let userInfo = JSON.parse(localStorage.getItem('user_info'));
      //     let name = WINDOW_LOCAL_ORIGIN + '_' + userInfo.id +'_'+'qb_info';
      //     let qbInfo = JSON.parse(localStorage.getItem(name));
      //     qbId = qbInfo.id;
      // }

      inputData.qb_id = this.curChooseBankId;//题库ID


      let {list, pages} = await this.$api.getRealList(inputData);
      this.questionBankList = [];
      this.$nextTick(() => {
        for (let question of list) {
          question.exam_time = (question.exam_time / 60) + "分钟";
          this.questionBankList.push(question);
        }
      });
      this.pagingData.total = pages.total;
      this.pagingData.offset = pages.offset;
      this.loadingStatus = false
    },

    // 拖拽排序数据提交，请求接口
    async dragSortSubmit(orderIds) {
      let data = {
        ids: orderIds
      };
      let info = await this.$api.orderRealList(data);
      if (info == 'ok') {
        this.$nextTick(() => {
          this.initRealList()
        })
      }
      this.$message.success('保存成功!')
    },

    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
        },
        onEnd: () => {
          let Ids = [];
          let tmp = el.querySelectorAll('.drag-handle');
          for (let i = 0, len = tmp.length; i < len; i++) {
            Ids.push(tmp[i].dataset.id)
          }
          this.dragSortSubmit(Ids)
        },
      })
    },

    //搜索
    onSearchClick() {
      this.$refs['apeTable'].resetCurPageSize();
      this.initRealList(DEL, this.searchForm);
    },

    //搜索重置
    onResetSearch() {
      this.searchForm = {};
      this.initRealList();
    },

    //导入文件抽屉确定
    async importDrawerConfirm() {
      // this.resetFormData();
      this.drawerImportData.visible = true;
      this.drawerImportData.loading = true;

      let fileId = this.importDrawerForm.file_id;
      if (!fileId) {
        this.$message.error("请先上传文件");
        this.drawerImportData.loading = false;
        return;
      }

      let info = await this.$api.previewImportQuestion(fileId)
      console.log(info)
      this.configList = info
      this.showPreviewDialog = true

      // let data = {
      //   file: this.importDrawerForm.file_id,
      //   extra_params: {
      //     'qb_id': qbId,
      //     'in_question_type': false,
      //     'in_chapter': false,
      //     'in_real': true
      //   }
      // };
      //
      //
      // let info = await this.$api.storeImportQuestion(data);
      //
      // if (info == "ok") {
      //   this.$message.success("上传成功");
      //   // this.$router.push('/import_file_list');
      // } else {
      //   this.$message.error("上传失败");
      // }
      //
      // await this.initRealList()
      // this.uploadFileList = [];
      // this.drawerImportData.visible = false;
      this.drawerImportData.loading = false;
    },

    async onPreviewFinish() {
      const loading = this.$loading({
        lock: true,
        text: '正在导入，请勿离开当前页面',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let qbId = this.curChooseBankId;

      let data = {
        file: this.importDrawerForm.file_id,
        extra_params: {
          'qb_id': qbId,
          'in_question_type': false,
          'in_chapter': false,
          'in_real': true
        }
      };

      let info = await this.$api.storeImportQuestion(data).catch(()=>{
        loading.close()
      });

      loading.close()
      if (info === "ok") {
        this.$message.success("上传成功");
        window.location.reload()
        // this.$router.push('/import_file_list');
      } else {
        this.$message.error("上传失败");
      }

      await this.initRealList()
      this.uploadFileList = [];
      this.drawerImportData.visible = false;
    },

    //导入文件抽屉取消
    importDrawerClose() {
      this.resetFormData();
      this.uploadFileList = [];
      this.drawerImportData.visible = false;
      this.drawerImportData.loading = true
    },

    // 文件上传成功回调
    handleUploadSuccess(file, fileList) {
      console.log("file", file);
      this.importDrawerForm.file_id = file.id;
    },
    // 文件删除回调
    handleUploadRemove(file, fileList) {
      this.importDrawerForm.file_id = 0;
    },

    onChangeQuestionBankClick() {
      this.initRealList();
    },

  },
  mounted() {
    this.initRealList(); //历史真题列表
    let axiosBaseUrl = process.env.VUE_APP_AXIOS_BASE_URL;
    let url = axiosBaseUrl.substring(0, axiosBaseUrl.lastIndexOf('/'));
    this.temUrl = url + '/attachments/moban.xlsx';
    this.$nextTick(() => {
      this.dragSort()
    })
  },
  watch: {
    "$route.matched": function (n, o) {
      if (n.length === 2) {
        this.initRealList();
      }
    },

    'curChooseBankId'(newVal) {
      if (newVal) {
        this.initRealList(DEL);
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0px
  margin-right 12px

.el-radio.is-bordered
  width 100px

.el-color-picker
  position absolute
</style>