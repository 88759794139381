<template>
    <div>
        <PageHeaderLayout>
            <div class="main-page-content">
                <el-form :model="searchForm" ref="searchForm" label-width="48px" inline size="small" class="search-form">
                    <el-form-item label="题型" prop="question_type">
                        <el-select v-model="searchForm.question_type" clearable placeholder="请选择题型">
                            <el-option
                                v-for="type in typeList"
                                :key="type.id"
                                :label="type.text"
                                :value="type.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-select v-model="searchForm.status" clearable placeholder="请选择状态">
                            <el-option
                                v-for="status in statusList"
                                :key="status.id"
                                :label="status.text"
                                :value="status.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="题目" prop="topic">
                        <el-input v-model="searchForm.topic" clearable />
                    </el-form-item>
                    <el-form-item label="昵称" prop="nick_name">
                        <el-input v-model="searchForm.nick_name" clearable />
                    </el-form-item>
                    <el-form-item label="手机" prop="phone">
                        <el-input v-model="searchForm.phone" clearable />
                    </el-form-item>
                    <div style="width: 160px; text-align: right">
                        <el-button type="primary" size="small" @click="onSearchClick">搜索</el-button>
                        <el-button size="small" @click="onResetSearch">重置</el-button>
                    </div>
                </el-form>
                <ApeTable
                    ref="apeTable"
                    :data="correctionList"
                    :columns="columns"
                    :loading="loadingStatus"
                    :pagingData="pagingData"
                    @switchPaging="switchPaging"
                    highlight-current-row
                >
                    <el-table-column v-if="buttonType === 'icon'" label="操作" width="150" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip
                                content="纠错"
                                placement="left"
                                v-if="userPermissions.indexOf('correction_edit') !== -1"
                            >
                                <el-button size="mini" icon="el-icon-edit" @click="modifyQuestion(scope.row)" />
                            </el-tooltip>

                            <el-tooltip
                                content="更改状态"
                                placement="right"
                                v-if="userPermissions.indexOf('correction_status') !== -1"
                            >
                                <el-button size="mini" icon="el-icon-edit-outline" @click="modifyStatus(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>

                    <el-table-column v-if="buttonType === 'text'" label="操作" width="150" fixed="right">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                v-if="userPermissions.indexOf('correction_edit') !== -1"
                                @click="modifyQuestion(scope.row)"
                            >修改</el-button>
                        </template>

                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                v-if="userPermissions.indexOf('correction_status') !== -1"
                                @click="modifyStatus(scope.row)"
                            >更改状态</el-button>
                        </template>
                    </el-table-column>
                </ApeTable>
            </div>
        </PageHeaderLayout>

        <question-bank-question-dialog
            ref="questionDialog"
            :dialogConfig="dialogConfig"
            :questionType="curType"
            :primaryId="primaryId"
            :questionData="curDialogFromData"
            @dialog-confirm="onDialogConfirmClick"
            @dialog-cancel="onDialogCancelClick"
        />

        <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
            <template slot="ape-drawer">
                <div>
                    <span style="color:#999;">当前状态： </span>
                    <span style="color: #E6A23C">{{curStatus}}</span>
                </div>
                <div style="margin-top: 10px">
                    <span style="color: #999">选择状态：</span>
                    <el-select v-model="selectedStatus" clearable placeholder="请选择状态">

                        <el-option
                            v-for="status in statusList"
                            :key="status.id"
                            :label="status.text"
                            :value="status.id"
                        />
                    </el-select>
                </div>
            </template>
        </ApeDrawer>
    </div>
</template>

<script>
    const DEL = "del";

    import PageHeaderLayout from '@/layouts/PageHeaderLayout'
    import ApeTable from '@/components/ApeTable'
    import searchForm from "../mixins/searchForm";
    import QuestionBankQuestionDialog from '@/components/questionBankQuestionDialog';
    import {mapGetters} from 'vuex'
    import ApeDrawer from '@/components/ApeDrawer'

    export default {
        name: 'correction',
        components: {
            PageHeaderLayout,
            ApeTable,
            QuestionBankQuestionDialog,
            ApeDrawer,
        },
        mixins: [searchForm],
        data() {
            return {
                loadingStatus: false,
                columns: [
                    {
                        title: '来源',
                        value: 'location',
                    },
                    {
                        title: '题目',
                        value: 'topic',
                      width : '300'
                        // value_display: 'topic_alias'
                    },
                    {
                        title: "题型",
                        value: "question_type",
                        value_display: 'question_type_alias'
                    },
                    {
                        title: '错误类型',
                        value: 'error_type',
                        value_display: 'error_type_alias',
                    },
                    {
                        title: '错误描述',
                        value: 'error_memo',
                    },
                    {
                        title: '用户昵称',
                        value: 'nick_name'
                    },
                    {
                        title: '用户手机',
                        value: 'phone'
                    },
                    {
                        title: '状态',
                        value: 'status',
                        value_display: 'status_alias',
                        class_plan: [
                            {
                                value: "applied",
                                class: ["el-tag", "el-tag--warning"]
                            },
                            {
                                value: "done",
                                class: "el-tag el-tag--success"
                            },
                            {
                                value: "canceled",
                                class: "el-tag el-tag--danger"
                            }
                        ]
                    },
                ],
                // 表格列表数据
                correctionList: [],
                statusList: [],
                // 分页信息
                pagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },
                searchForm: {
                    status: '',
                    question_type: '',
                    topic: '',
                },
                typeList: [],

                //弹框信息
                dialogConfig: {
                    visible: false,//是否显示弹框
                    title: '题目纠错',
                    width: '50%',
                    loading: false,
                    modal: false
                },
                curType: "",
                primaryId: "",
                curDialogFromData: {},

                // 抽屉数据
                drawerData: {
                    visible: false,
                    title: '纠错状态',
                    width_height: '500px',
                },
                selectedStatus: '',
                curStatus: '',
            }
        },
        computed: {
            ...mapGetters(['userPermissions', 'buttonType'])
        },
        mounted() {
            // 表码
            this.getQuestionTypeList();
            this.queryCorrectionList();
            this.getCorrectionStatus();

        },
        methods: {
            // 获取纠错错误类型
            async getCorrectionStatus() {
                this.statusList = await this.$api.commonDict('user_err_correction_status');
            },

            // 纠错列表
            async queryCorrectionList(type) {
                try {
                    this.loadingStatus = true;
                    let inputData = this.$refs['apeTable'].getPagingInfo(type);
                    for (let param in this.searchForm) {
                        if (!this.searchForm[param]) {
                            continue;
                        }
                        inputData[param] = this.searchForm[param];
                    }
                    let res = await this.$api.queryCorrectionList(inputData)
                    let {data, pages} = res
                    data && (this.correctionList = data);
                    pages && (this.pagingData.total = pages.total);
                    pages && (this.pagingData.offset = pages.offset);
                    this.loadingStatus = false;
                } catch (e) {
                    this.loadingStatus = false;
                }
            },

            // 切换页码操作
            switchPaging() {
                this.queryCorrectionList()
            },

            // 修改
            async modifyQuestion(rowData) {
                if(!rowData) return

                this.dialogConfig.visible = true;
                this.curType = rowData.question_type
                this.primaryId = rowData.qbq_id + '';
                let {info} = await this.$api.getQuestionBankQuestionInfo(this.primaryId);
                this.curDialogFromData = info;
            },

            //搜索
            onSearchClick(formData) {
                this.$refs['apeTable'].resetCurPageSize();
                this.queryCorrectionList(DEL, formData);
            },

            //搜索重置
            onResetSearch() {
                this.searchForm = {};
                this.queryCorrectionList();
            },

            // 修改确认
            async onDialogConfirmClick(formData) {
                this.dialogConfig.loading = true;
                await this.$api.updateQuestionBankQuestion(formData);
                this.$message.success('修改成功')
                this.dialogConfig.loading = false;
                this.dialogConfig.visible = false;
                this.curDialogFromData = {};
                this.primaryId = ''
                await this.queryCorrectionList(DEL);
            },

            // 取消修改
            onDialogCancelClick() {
                this.dialogConfig.visible = false;
                this.primaryId = ''
                this.$nextTick(()=>{
                    this.$refs.questionDialog.resetForm();
                })
            },

            // 打开状态修改抽屉
            modifyStatus(rowData) {
                if(!rowData) return
                this.primaryId = rowData.id + ''
                this.curStatus = rowData.status_alias
                this.drawerData.visible = true;
            },

            // 抽屉关闭
            drawerClose() {
                this.drawerData.visible = false;
                this.primaryId = ''
                this.selectedStatus = ''
                this.curStatus = ''
            },

            async drawerConfirm() {
                if(!this.primaryId)  return
                if(!this.selectedStatus) {
                    this.$message('请选择状态')
                    return
                }
                try {
                    let params = {}
                    params.id = this.primaryId
                    params.status = this.selectedStatus
                    await this.$api.handleUserErrorCorrection(params)
                    this.$message.success('修改成功')
                    this.drawerClose();
                    await this.queryCorrectionList(DEL);
                } catch (e) {
                    this.$message.error('修改失败')
                    this.drawerClose();
                    await this.queryCorrectionList(DEL);
                }
            },
        },
    }
</script>

<style lang="stylus" scoped>
    .table-header
        margin-bottom 12px

    .drag-handle
        font-size 24px
        cursor pointer

    .el-input-group__prepend, .el-input-group__append
        background #ffffff
        padding 0 12px

    .el-input-group__append
        color #ffffff
        background #1890ff

    .el-popover .el-checkbox-group .el-checkbox
        margin-left 0
        margin-right 12px

    .el-radio.is-bordered
        width 100px

    .el-color-picker
        position absolute

    .search-form
        display flex
        float left
        .el-form-item
            flex: 1
        /deep/ .el-form-item__content
            width: calc(100% - 48px)
        /deep/ .el-select
            width 100%
</style>
